<template>
	<div class="plan">
		<div class="plan__item">
			<div class="z-subheading-small z-subheading-small--spaced">
				{{ $t('siteSettings.subscription.planCard.plan') }}
			</div>
			<div
				v-qa="`sitesettings-subscription-currentplan-${currentPlan}`"
				class="z-body-small"
			>
				{{ currentPlan }}
			</div>
		</div>
		<div class="plan__item">
			<div class="z-subheading-small z-subheading-small--spaced">
				{{ $t('siteSettings.subscription.planCard.paymentPlan') }}
			</div>
			<div
				v-qa="`sitesettings-subscription-currentplan-${planDurationTranslation}`"
				class="z-body-small"
			>
				{{ planDurationTranslation }}
			</div>
		</div>
		<div class="plan__item">
			<div class="z-subheading-small z-subheading-small--spaced">
				{{ $t('siteSettings.subscription.planCard.lastPayment') }}
			</div>
			<div
				v-qa="`sitesettings-subscription-currentplan-${formatDate(subscriptionData.lastPayment)}`"
				class="z-body-small"
			>
				{{ formatDate(subscriptionData.lastPayment) }}
			</div>
		</div>
		<div class="plan__item">
			<div class="z-subheading-small z-subheading-small--spaced">
				{{ $t('siteSettings.subscription.planCard.renewalDate') }}
			</div>
			<div
				v-qa="`sitesettings-subscription-currentplan-${formatDate(subscriptionData.expiration)}`"
				class="z-body-small"
			>
				{{ formatDate(subscriptionData.expiration) }}
			</div>
		</div>
		<div class="plan__footer">
			<ZyroButton
				theme="primary"
				color="red"
				data-qa="sitesettings-subscription-btn-upgrade"
				class="plan__upgrade-btn"
				@click="$emit('upgrade')"
			>
				{{ $t('common.upgrade') }}
			</ZyroButton>
			<ZyroButton
				theme="plain"
				color="black"
				data-qa="sitesettings-subscription-btn-cancelplan"
				class="plan__cancel-btn"
				@click="$emit('cancel-plan')"
			>
				{{ $t('common.cancelPlan') }}
			</ZyroButton>
		</div>
	</div>
</template>
<script>
import i18n from '@/i18n/setup';
import { getLocaleFromLocalStorage } from '@/utils/i18n/localStorageLocale';
import { getMapValue } from '@/utils/object';

const PLAN_DURATIONS = {
	'1M': i18n.t('siteSettings.subscription.planCard.planDuration.1M'),
	'1Y': i18n.t('siteSettings.subscription.planCard.planDuration.1Y'),
	'2Y': i18n.t('siteSettings.subscription.planCard.planDuration.2Y'),
	'4Y': i18n.t('siteSettings.subscription.planCard.planDuration.4Y'),
	default: i18n.t('siteSettings.subscription.planCard.planDuration.1M'),
};

export default {
	props: {
		subscriptionData: {
			type: Object,
			default: null,
		},
		currentPlan: {
			type: String,
			default: '',
		},
	},
	computed: {
		planDuration: ({ subscriptionData }) => subscriptionData.plan.slice(-2),
		planDurationTranslation: ({ planDuration }) => getMapValue(planDuration, PLAN_DURATIONS),
	},
	methods: {
		formatDate(input) {
			return new Date(input).toLocaleDateString(getLocaleFromLocalStorage(), {
				year: 'numeric',
				month: 'long',
				day: 'numeric',
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.plan {
	margin-bottom: 8px;
	background: $light;

	&__item {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: 24px 32px 16px 32px;
		border-bottom: 1px solid $grey-100;

		@include mobile-view {
			flex-direction: column;
			padding: 16px 16px 8px;
		}
	}

	&__user-info {
		text-align: right;

		@include mobile-view {
			text-align: left;
		}
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 32px;

		@include mobile-view {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			padding: 32px 16px;
		}
	}

	&__cancel-btn {
		order: 1;
		margin-right: 32px;

		@include mobile-view {
			order: 2;
			margin-top: 24px;
		}
	}

	&__upgrade-btn {
		order: 2;

		@include mobile-view {
			order: 1;
		}
	}
}
</style>
