<template>
	<div class="cancel-plan">
		<div class="cancel-plan__left-side">
			<i18n
				path="siteSettings.subscription.cancelPlan.heading"
				tag="h2"
				class="z-h5 z-font-weight-light"
			>
				{{ currentPlan }}
			</i18n>
			<i18n
				path="siteSettings.subscription.cancelPlan.text"
				tag="p"
				class="z-body"
			>
				{{ currentPlan }}
				<span class="z-font-weight-bold">{{ formatDate(expirationDate) }}</span>
			</i18n>
			<ZyroButton
				theme="primary"
				color="red"
				class="cancel-plan__button"
				@click="$emit('choose-plan')"
			>
				{{ $t('common.choosePlan') }}
			</ZyroButton>
		</div>
		<div class="cancel-plan__right-side">
			<ul class="feature-list">
				<li
					v-for="(feature, key) in planFeatureNames"
					:key="key"
					class="feature-list__item"
				>
					<ZyroSvg
						name="x--red"
						class="feature-list__item-icon"
					/>
					<p class="feature-list__item-text">
						<VueMarkdown>
							{{ feature }}
						</Vuemarkdown>
						<span class="z-font-weight-bold feature-list__item-value">
							{{ key === 0 && currentPlan === 'BASIC' ? '3GB' : '' }}
							{{ key ===1 && currentPlan === 'BASIC' ? '1GB' : '' }}
							{{ (key === 0 || key === 1) && currentPlan !== 'BASIC' ? 'UNLIMITED' : '' }}
						</span>
					</p>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
import VueMarkdown from 'vue-markdown';
import { mapActions } from 'vuex';

const staticPlanData = {
	FREE: {
		price: 0,
		featureValues: {
			bandwidth: '500 MB',
			storage: '500 MB',
			ssl: true,
			seo: true,
			templates: true,
			ai: true,
			noAdds: true,
		},
	},
	BASIC: {
		priceId: 'BASIC1Y',
		basePriceId: 'BASIC1M',
		featureValues: {
			bandwidth: '3 GB',
			storage: '1 GB',
			ssl: true,
			seo: true,
			templates: true,
			ai: true,
			noAdds: true,
			connectDomain: true,
		},
	},
	UNLEASHED: {
		priceId: 'UNLEASH1Y',
		basePriceId: 'UNLEASH1M',
		featureValues: {
			bandwidth: true,
			storage: true,
			ssl: true,
			seo: true,
			templates: true,
			ai: true,
			noAdds: true,
			connectDomain: true,
			freeDomain: true,
			googleAnalytics: true,
			liveChat: true,
			fbPixel: true,
			googleTagManager: true,
			remarketing: true,
		},
	},
	ECOMMERCE: {
		priceId: 'ECOMMERCE1Y',
		basePriceId: 'ECOMMERCE1M',
		featured: true,
		featureValues: {
			bandwidth: true,
			storage: true,
			ssl: true,
			seo: true,
			templates: true,
			ai: true,
			noAdds: true,
			connectDomain: true,
			freeDomain: true,
			googleAnalytics: true,
			liveChat: true,
			fbPixel: true,
			googleTagManager: true,
			remarketing: true,
			acceptOnlinePayments: true,
			commisionFree: true,
			fullEcommerce: true,
			unlimitedProducts: '100',
			emailNotifications: true,
			discountCoupons: true,
			giftCards: true,
			orderManagement: true,
			inventoryManagement: true,
			shippingAndTaxManagement: true,
			paymentOptions: true,
		},
	},
	ECOMMERCEPLUS: {
		priceId: 'ECOMMERCEPLUS1Y',
		basePriceId: 'ECOMMERCEPLUS1M',
		featureValues: {
			bandwidth: true,
			storage: true,
			ssl: true,
			seo: true,
			templates: true,
			ai: true,
			noAdds: true,
			connectDomain: true,
			freeDomain: true,
			googleAnalytics: true,
			liveChat: true,
			fbPixel: true,
			googleTagManager: true,
			remarketing: true,
			acceptOnlinePayments: true,
			commisionFree: true,
			fullEcommerce: true,
			unlimitedProducts: true,
			emailNotifications: true,
			discountCoupons: true,
			giftCards: true,
			orderManagement: true,
			inventoryManagement: true,
			shippingAndTaxManagement: true,
			paymentOptions: true,
			cartRecovery: true,
			multipleLanguages: true,
			productFilter: true,
			fbShop: true,
			instagramStore: true,
			amazon: true,
		},
	},
};

export default {
	staticPlanData,
	components: { VueMarkdown },
	props: {
		currentPlan: {
			type: String,
			default: '',
		},
		expirationDate: {
			type: String,
			default: '',
		},
	},
	computed: {
		planFeatureNames() {
			if (!this.currentPlan) {
				return [];
			}

			return Object.keys(staticPlanData[this.currentPlan]?.featureValues)
				.map((featureId) => this.groupedFeatures[featureId]?.name);
		},
		groupedFeatures() {
			return Object.values(this.$t('pricingShared.groupedFeatures')).reduce((accumulator, { features }) => ({
				...accumulator,
				...features,
			}), {});
		},
	},
	mounted() {
		this.setNpsVisibility(true);
	},
	methods: {
		...mapActions('nps', ['setNpsVisibility']),
		formatDate(input) {
			return new Date(input).toLocaleDateString('en-US', {
				year: 'numeric',
				month: 'long',
				day: 'numeric',
			});
		},
	},
};
</script>
<style lang="scss" scoped>
$padding-top: 48px;
$padding-bottom: 48px;
$padding-side: 40px;

.cancel-plan {
	position: relative;
	display: flex;
	margin-bottom: 8px;
	background: $light;

	@include mobile-view {
		flex-direction: column;
		padding: 32px 16px 96px 16px;
	}

	&__left-side {
		width: 65%;
		padding: $padding-top 64px $padding-bottom $padding-side;
		border-right: 1px solid $grey-100;

		@include mobile-view {
			width: 100%;
			padding: 0;
			margin-bottom: 24px;
			border-right: none;
		}
	}

	&__right-side {
		padding: $padding-top $padding-side $padding-bottom 56px;

		@include mobile-view {
			padding: 0;
		}
	}

	&__button {
		margin: 40px 0;

		@include mobile-view {
			position: absolute;
			bottom: 32px;
			left: 16px;
			margin: 0;
		}
	}
}

.feature-list {
	list-style: none;

	&__item {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 8px;
	}

	&__item-text {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&__item-value {
		margin-left: 3px;
	}

	&__item-icon {
		margin: 0 8px 3px;
	}
}
</style>
