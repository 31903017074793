<template>
	<section class="subscription">
		<ZyroSettingsTitle v-if="showCancelPlanCard || showCurrentPlan">
			{{ $t('siteSettings.nav.subscription') }}
		</ZyroSettingsTitle>
		<template v-if="showProductsTable">
			<i18n
				path="siteSettings.subscription.pricing.heading"
				tag="h3"
				class="z-h5 z-font-weight-light subscription__subtitle "
			>
				{{ hasActivePlan
					? $t(`pricingShared.plans.${currentPlan}.name`)
					: $t(`pricingShared.plans.FREE.name`) }}.
				<template v-if="planIsUpgradable">
					{{ $t('siteSettings.subscription.pricing.headingUpgradable') }}
				</template>
			</i18n>
			<Pricing
				v-if="products"
				v-bind="{
					products,
					subscription,
					currentPlan
				}"
			/>
		</template>
		<PlanCard
			v-if="showCurrentPlan"
			:subscription-data="subscription"
			:current-plan="currentPlan"
			@upgrade="showProducts"
			@cancel-plan="cancelPlan(subscription.siteId)"
		/>
		<CancelPlanCard
			v-if="showCancelPlanCard"
			:current-plan="currentProductName"
			:expiration-date="subscription.expiration"
			@choose-plan="showProducts"
		/>
	</section>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapActions,
} from 'vuex';

import { cancelSubscription as cancelSubscriptionChargeBee } from '@/api/BillingApi';
import { cancelSubscription } from '@/api/SubscriptionsApi';
import ZyroSettingsTitle from '@/components/site-settings/components/ZyroSettingsTitle.vue';
import CancelPlanCard from '@/components/site-settings/pages/subscription/components/CancelPlanCard.vue';
import PlanCard from '@/components/site-settings/pages/subscription/components/PlanCard.vue';
import Pricing from '@/components/site-settings/pages/subscription/components/Pricing.vue';
import Plans from '@/i18n/_base/site-settings/pricingShared.json';
import {
	mapActionsNotifications,
	NOTIFY,
} from '@/store/builder/notifications';

import {
	PLAN_ECOMMERCE_PLUS,
	PLAN_FREE,
	SUBSCRIPTION_STATUS_ACTIVE,
} from './constants';

export default {
	components: {
		ZyroSettingsTitle,
		PlanCard,
		CancelPlanCard,
		Pricing,
	},
	data() {
		return {
			showProductsTable: false,
			showCancelPlanCard: false,
			showCurrentPlan: false,
			planDuration: '',
			Plans,
		};
	},
	computed: {
		...mapState(['hasActivePlan']),
		...mapState('subscription', [
			'products',
			'subscription',
			'loading',
		]),
		...mapGetters('user', ['isChargeBeeUser']),
		planIsUpgradable() {
			return !this.subscription || !this.subscription.plan.includes(PLAN_ECOMMERCE_PLUS);
		},
		currentProduct() {
			return this.products.find((product) => this.subscription?.plan === product.id);
		},
		currentPlan() {
			if (this.loading) {
				return null;
			}

			return this.currentProduct?.group || this.subscription?.plan || PLAN_FREE;
		},
		currentProductName() {
			return this.currentProduct?.group;
		},
	},
	async mounted() {
		try {
			await this.getSubscription();
			if (this.subscription?.status === SUBSCRIPTION_STATUS_ACTIVE) {
				this.showCurrentPlan = true;
			} else {
				this.showProductsTable = true;
			}
		} catch (error) {
			this.notify({
				origin: 'Subscription.vue',
				message: `Could not get subscription details. ${error.message || error}`,
			});
		}
	},
	methods: {
		...mapActions('subscription', ['getSubscription']),
		...mapActionsNotifications({ notify: NOTIFY }),
		showProducts() {
			this.showProductsTable = true;
			this.showCancelPlanCard = false;
			this.showCurrentPlan = false;
		},
		async cancelPlan(siteId) {
			try {
				const result = this.isChargeBeeUser
					? await cancelSubscriptionChargeBee(siteId)
					: await cancelSubscription(siteId);

				this.showProductsTable = false;
				this.showCancelPlanCard = result;
				this.showCurrentPlan = false;
			} catch (error) {
				this.showProductsTable = false;
				this.showCancelPlanCard = true;
				this.showCurrentPlan = !!error;
				this.notify({
					origin: 'Subscription.vue',
					message: `Could not cancel subscription. ${error.message || error}`,
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.subscription {
	&__subtitle {
		margin: 32px 0;
	}
}
</style>
