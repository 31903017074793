<template>
	<div>
		<PricingList
			class="pricing"
			v-bind="{
				plans,
				groupedFeatures,
				...$t('pricingShared.sharedLabels')
			}"
		>
			<ZyroButton
				slot="plan-cta"
				slot-scope="props"
				v-qa="`sitesettings-subscription-btn-select-${props.planId}`"
				:class="{ 'pricing__button--hidden': currentPlan === props.planId }"
				theme="primary"
				color="plump-purple"
				:disabled="planIsDisabled(props.planId)"
				:href="planLink(props.planId)"
			>
				{{ $t(`common.${planIsDisabled(props.planId) ? 'comingSoon' : 'select'}`) }}
			</ZyroButton>
		</PricingList>
		<PaymentInfo :labels="paymentLabels" />
	</div>
</template>
<script>
import {
	featureObjectToArray,
	generatePlans,
	PricingList,
	PaymentInfo,
} from '@zyro-inc/shared-ui/components/pricing';

import EventLogApi from '@/api/EventLogApi';
import {
	REDIRECT_PARAM_KEYS,
	REDIRECT_PARAM_VALUES,
	WWW_REDIRECT_PATHS,
} from '@/constants';
import { useRedirects } from '@/use/useRedirects';

const {
	PLAN,
	AUTOUPGRADE,
	RETURN,
} = REDIRECT_PARAM_KEYS;
const {
	TRUE,
	RETURN_BUILDER,
} = REDIRECT_PARAM_VALUES;
const { PAYMENTS_PERIODS } = WWW_REDIRECT_PATHS;

// TODO: load this from backend when featuresets are implemented
const disabledPlans = [];

const staticPlanData = {
	FREE: {
		price: 0,
		featureValues: {
			bandwidth: '500 MB',
			storage: '500 MB',
			ssl: true,
			seo: true,
			templates: true,
			ai: true,
			noAdds: true,
		},
	},
	BASIC: {
		priceId: 'BASIC4Y',
		basePriceId: 'BASICBASE',
		featureValues: {
			bandwidth: '3 GB',
			storage: '1 GB',
			ssl: true,
			seo: true,
			templates: true,
			ai: true,
			noAdds: true,
			connectDomain: true,
		},
	},
	UNLEASHED: {
		priceId: 'UNLEASH4Y',
		basePriceId: 'UNLEASHBASE',
		featureValues: {
			bandwidth: true,
			storage: true,
			ssl: true,
			seo: true,
			templates: true,
			ai: true,
			noAdds: true,
			connectDomain: true,
			freeDomain: true,
			googleAnalytics: true,
			liveChat: true,
			fbPixel: true,
			googleTagManager: true,
			remarketing: true,
		},
	},
	ECOMMERCE: {
		priceId: 'ECOMMERCE4Y',
		basePriceId: 'ECOMMERCEBASE',
		featured: true,
		featureValues: {
			bandwidth: true,
			storage: true,
			ssl: true,
			seo: true,
			templates: true,
			ai: true,
			noAdds: true,
			connectDomain: true,
			freeDomain: true,
			googleAnalytics: true,
			liveChat: true,
			fbPixel: true,
			googleTagManager: true,
			remarketing: true,
			acceptOnlinePayments: true,
			commisionFree: true,
			fullEcommerce: true,
			unlimitedProducts: '100',
			emailNotifications: true,
			discountCoupons: true,
			giftCards: true,
			orderManagement: true,
			inventoryManagement: true,
			shippingAndTaxManagement: true,
			paymentOptions: true,
		},
	},
	ECOMMERCEPLUS: {
		priceId: 'ECOMMERCEPLUS4Y',
		basePriceId: 'ECOMMERCEPLUSBASE',
		featureValues: {
			bandwidth: true,
			storage: true,
			ssl: true,
			seo: true,
			templates: true,
			ai: true,
			noAdds: true,
			connectDomain: true,
			freeDomain: true,
			googleAnalytics: true,
			liveChat: true,
			fbPixel: true,
			googleTagManager: true,
			remarketing: true,
			acceptOnlinePayments: true,
			commisionFree: true,
			fullEcommerce: true,
			unlimitedProducts: true,
			emailNotifications: true,
			discountCoupons: true,
			giftCards: true,
			orderManagement: true,
			inventoryManagement: true,
			shippingAndTaxManagement: true,
			paymentOptions: true,
			cartRecovery: true,
			multipleLanguages: true,
			productFilter: true,
			fbShop: true,
			instagramStore: true,
			amazon: true,
		},
	},
};

export default {
	components: {
		PricingList,
		PaymentInfo,
	},
	props: {
		currentPlan: {
			type: String,
			default: '',
		},
		products: {
			type: Array,
			default: () => [],
		},
		discountAmount: {
			type: Number,
			default: 0,
		},
		subscription: {
			type: Object,
			default: () => ({}),
		},
	},
	setup() {
		const { getRedirectToWWWUrl } = useRedirects();

		return { getRedirectToWWWUrl };
	},
	data() {
		return {
			paymentLabels: {
				MONEY_GUARANTEE_TITLE: this.$t('pricingShared.additionalInfo.moneyGuarantee.title'),
				MONEY_GUARANTEE_TEXT: this.$t('pricingShared.additionalInfo.moneyGuarantee.text'),
				PAYMENT_METHODS_TITLE: this.$t('pricingShared.additionalInfo.paymentMethods.title'),
				SSL_TITLE: this.$t('pricingShared.additionalInfo.security.title'),
				SSL_TEXT: this.$t('pricingShared.additionalInfo.security.text'),
			},
		};
	},
	computed: {
		planData() {
			if (staticPlanData[this.currentPlan]) {
				staticPlanData[this.currentPlan].current = true;
			}

			return staticPlanData;
		},
		groupedFeatures() {
			return featureObjectToArray(this.$t('pricingShared.groupedFeatures'));
		},
		plans() {
			return generatePlans(this.$t('pricingShared.plans'), this.planData, this.products).filter((plan) => ![
				'FREE',
				'TRIAL',
			].includes(plan.id));
		},
	},
	mounted() {
		EventLogApi.logEvent({
			eventName: 'user.productView',
			eventProperties: {
				ecommerce: { detail: { products: this.products } },
				path: this.$route.path,
			},
			amplitude: false,
		});
	},
	methods: {
		planLink(planId) {
			if (this.planIsDisabled(planId)) {
				return null;
			}

			const parameters = {
				[PLAN]: planId.toLowerCase(),
				[AUTOUPGRADE]: TRUE,
				[RETURN]: RETURN_BUILDER,
			};

			return this.getRedirectToWWWUrl({
				path: PAYMENTS_PERIODS,
				params: parameters,
			});
		},
		planIsDisabled(planId) {
			return disabledPlans.includes(planId);
		},
	},
};
</script>
<style lang="scss" scoped>
.pricing {
	padding: 40px;
	margin-bottom: 64px;
	background-color: $light;

	&__button {
		&--hidden {
			pointer-events: none;
			opacity: 0;
		}
	}

	@include zyro-media($settings-media-tablet) {
		padding: 0;
		background-color: transparent;
	}
}
</style>
